.TestResults {
  h2 {
    @media screen and (max-width: 992px) {
      font-size: 32px;
    }
  }
}


.pageHeadingAlignment {
  // cause of transform: translateY(-50px); for PageHeading
  margin-top: -55px;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 0;
  }
}

.title {
  text-align: center;
  color: var(--black);
}

.questions {
  h3:nth-child(1) {
    font-family: "Roboto Slab", serif;
    font-size: 32px;
    line-height: 1.28;
    color: var(--black);
    font-weight: 500;
    margin-bottom: 28px;
    max-width: 458px;
  }

  h3:not(:nth-child(1)) {
    padding: 26px 0 0 0;
    border-top: solid 1px var(--brown-grey-30);
    font-weight: 300;
    margin-bottom: 16px;
  }

  h3:not(:nth-child(2)) {
    border-top: unset;
    padding: 10px 0 0 0;

    @media screen and (max-width: 992px) {
      font-size: 24px;
      margin: 0 0 10px 0;
    }
  }

  & > button {
    padding: 16px 24px;
    line-height: 24px;
    margin: 0 0 35px 0;

    &:focus,
    &:active {
      padding: 14px 24px;
    }
  }
}

.ageButtons {
  margin-bottom: 33px;

  @media screen and (min-width: 576px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 32px;
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 15px;
  }
}
.activeBtn,
.ageButton,
.testExperience {
  padding: 0 32px;
  min-height: 56px;
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  border: solid 1px var(--brown-grey-30);
  background-color: white;
  outline: none;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 100%;
  color: var(--black);
}

.activeBtn {
  border: 3px solid var(--bright-orange);
}

.testExperience {
  padding: 13px 21px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;

  &:focus,
  &:active {
    border: solid 1px var(--brown-grey-30);
  }
}

.symptomsButtons {
  margin-bottom: 48px;

  @media screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }

  & > button {
    @media screen and (max-width: 480px) {
      min-width: initial;
      width: initial;
    }

    @media screen and (min-width: 480px) {
      margin-right: 40px;
    }
  }

  & > button:last-child {
    margin-right: 0;
  }
}

.nextSteps {
  padding: 57px 24px 79px 24px;
  margin-bottom: 48px;
  background-color: #ffebde;

  @media screen and (max-width: 992px) {
    padding: 26px 24px 79px 24px;
  }
}

.nextStepsHeading {
  font-family: "Roboto Slab", serif;
  color: var(--black);
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  margin: 0;
  padding: 0 0 28px 0;

  @media screen and (max-width: 1199px) {
    margin: 0 -64px;
  }

  @media screen and (max-width: 992px) {
    color: #fa7700;
    font-size: 24px;
    padding: 6px 0 28px 0;
    margin: 0 -64px;
  }

  @media screen and (max-width: 767px) {
    margin: 0 -40px;
  }

  @media screen and (max-width: 575px) {
    margin: 0 -25px;
  }
}

.nextStepsCard {
  padding: 32px 24px;
  background-color: white;
  border-radius: 10px;

  @media screen and (min-width: 992px) {
    padding: 38px 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
  }

  p {
    a {
      display: inline;
      color: var(--bright-orange);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    margin-top: 16px;

    @media screen and (max-width: 992px) {
      margin-top: 12px;
    }
  }
}

.nextStepsInnerHeading {
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .resultsDate {
    padding: 10px 0 20px 3px;

    @media screen and (max-width: 1060px) {
      margin: -69px 0 0 0;
    }

    @media screen and (max-width: 1002px) {
      margin: -97px 0 0 0;
    }

    @media screen and (max-width: 992px) {
      //margin: 0;
      //padding: 10px 0;
      //font-size: 16px;
      display: none;
    }
  }

  svg {
    display: none;

    @media screen and (min-width: 992px) {
      display: initial;
    }
  }

  h3 {
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: var(--black);
    font-family: "Roboto Slab", serif;
    margin: 0;
    max-width: 355px;

    @media screen and (max-width: 992px) {
      font-size: 24px;
      padding: 0 0 20px 0;
    }
  }
}

.nextStepsTextContent {
  p {
    font-weight: normal;
    color: #323232;
    margin: 0;
    padding: 0 0 20px 0;
  }

  p:not(:last-of-type) {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;
    padding: 0 0 10px 0;
  }
}
.noTests,
.prevTests {
  // margin-top: 75px;
  margin-bottom: 75px;

  @media screen and (max-width: 992px) {
    margin-top: -7px;
  }

  h4 {
    color: var(--bright-orange);
    margin: 0 0 6px 0;
  }

  a {
    display: block;
    margin: 0 auto;
  }
}

.noTests {
  p {
    max-width: 418px;
    padding: 14px 0 0 0;
    font-family: Roboto-Light, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;
  }
}

.prevTestCards {
  margin-bottom: 45px;
}

.prevTestCard {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--brown-grey-30);
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  .colorBox {
    width: 52px;
    height: 52px;
    flex-basis: 52px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  h3 {
    font-weight: 300;
  }
}

.prevTestHeading {
  border-bottom: 1px solid var(--brown-grey-30);
  padding: 0 0 10px 0;
  margin: 0 0 23px 0;

  @media screen and (min-width: 768px) {
    margin-bottom: 48px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;
  }
}

.prevTestResult {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    margin-bottom: initial;
  }

  h3 {
    margin: 0;
  }
}

.prevTestDate {
  font-weight: 300;
}

.prevTestInfo {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.prevTestInfoItem {
  font-weight: 300;
  border-top: 1px solid var(--brown-grey-30);
  padding: 16px 0;

  span {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &:last-child {
    border-top: none;
    order: -1;

    @media screen and (min-width: 768px) {
      order: initial;
    }
  }

  @media screen and (min-width: 768px) {
    border-top: initial;
    border-left: 1px solid var(--brown-grey-30);
    padding: 16px 40px;

    &:last-child {
      // padding-top: 0;
      // padding-bottom: 0;
      padding-right: 24px;
    }
  }
}

//.oval {
//  background-color: rgba(255, 103, 2, 0.5);
//  height: 56px;
//  width: 50%;
//  border-radius: 0 100% 0 0 ;
//}

.printLogo {
  display: none;
}