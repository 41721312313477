@font-face {
  font-family: Roboto-Light;
  src: local(Roboto-Light),
    url(../assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto-Regular),
    url(../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto-Medium), 
    url(../assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto-Bold),
    url(../assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto Slab";
  src: local(RobotoSlab-Regular),
    url(../assets/fonts/RobotoSlab/RobotoSlab-Regular.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-family: "Roboto Slab", serif;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.25;
  // color: var(--black);
}

h2 {
  font-family: "Roboto Slab", serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.28;
  // color: var(--black);
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  // color: var(--white);
}

h4 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.18;
  // color: var(--bright-orange);
}
