.GetTest {
}

.getTestForm {
  & label,
  & input,
  & p {
    display: block;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    margin-top: 0;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 32px;
  }
}

.errorText {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 30px 0 0 0;
}

.billingAddress {
  background-color: var(--very-light-pink-30);
  padding: 54px 0 150px;
  margin-top: 72px;
  margin-bottom: -132px;

  @media screen and (max-width: 776px) {
    padding: 44px 0 255px 0;
  }

  h4 {
    width: 100%;
  }

  & button {
    display: block;
    margin: 0 auto;
    margin-top: 56px;
  }
}

.billingAddress[data-style='authorized'] {
  margin-top: -64px;
  background-color: var(--white);
  padding: 25px 0 150px;

  @media screen and (max-width: 776px) {
    padding: 12px 0 255px;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: var(--bright-orange);
    border-bottom: 1px solid var(--brown-grey-30);
    padding: 0 0 20px 0;
  }
}
