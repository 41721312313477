@import "../../styles/mixins.scss";

.ourStory {
}

.heroImage {
  padding: 24px;
  height: 604px;
  text-align: center;
  background-image: url("../../assets/images/our-story-header-new.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 1150px) {
    height: 450px;
  }

  @media screen and (max-width: 1150px) {
    height: 350px;
  }

  @media screen and (max-width: 750px) {
    background-position: 72%;
    height: 375px;
  }

  .titleWrapper {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: stretch;
    padding: 0 0 0 32px;

    @media screen and (max-width: 750px) {
      justify-content: center;
    }

    h2 {
      margin-top: 120px;
      color: var(--white);
      display: inline-block;
      position: relative;
      font-size: 60px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;

      @media screen and (max-width: 750px) {
        font-size: 32px;
        margin-top: 60px;
        line-height: 1.28;
        font-family: Roboto;
        font-weight: 400;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px;
        height: 7px;
        width: 245px;
        background-color: var(--bright-orange);

        @media screen and (max-width: 750px) {
          height: 3px;
          width: 116px;
          bottom: -2px;
        }
      }
    }
  }
}

.textContent {
  background-color: #fff;
  padding: 28px 28px 0 28px;
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    transform: translateY(-50px);
    border-radius: 10px;
  }

  @media screen and (max-width: 1060px) {
    padding: 32px 25px 0 25px;
  }

  @media screen and (max-width: 760px) {
    padding: 25px 25px 0 25px;
  }

  h3 {
    font-size: 24px;
    margin: 0 0 98px 0;
    font-weight: 300;
    max-width: 604px;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #000000;

    @media screen and (max-width: 760px) {
      margin: 0 0 122px 0;
    }
  }
}

.storyText {
  position: relative;
  padding: 21px 0 0 0;

  &:before {
    position: absolute;
    content: "";
    top: -50px;
    left: -3px;
    width: calc(100% + 6px);
    height: 1px;
    background-color: var(--brown-grey-30);

    @media screen and (max-width: 760px) {
      top: -38px;
    }
  }

  @media screen and (min-width: 768px) {
    column-count: 2;
    column-gap: 40px;
  }

  p {
    font-weight: 300;
    margin: 0;
    padding: 0 0 21px 0;
    font-family: Roboto;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;

    &:first-child {
      margin-top: 0;
    }
  }
}

.orderTest {
  margin-top: 8px;
  padding: 0 24px;
  margin-bottom: 90px;

  @media screen and (max-width: 768px) {
    margin-top: 28px;
  }
}

.container {
  padding: 0;
  margin: 0 auto;
  max-width: 1024px;
}

.inner {
  background-color: #fef1e8;
  display: flex;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }

  .footerWrapper {
    display: flex;
    flex-direction: column;
    margin: 32px 0 39px 37px;

    @media screen and (max-width: 1080px) {
      margin: 38px 32px 0 32px;
      padding: 0 0 43px 0;
    }

    @media screen and (max-width: 360px) {
      padding: 0;
    }
  }

  .footerImage {
    max-width: 443px;
    background-image: url("../../assets/images/order-test-banner-image@3x.png");
    width: 100%;
    height: 100%;
    padding: 275px 0 0 0;
    background-size: 121%;
    background-position: -6% 65%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1080px) {
      background-image: url("../../assets/images/order-test-banner-mobile-image@3x.png");
      background-size: 53%;
      background-position: 134% 0;
      max-width: 100%;
    }

    @media screen and (max-width: 900px) {
      background-size: 70%;
      background-position: 170% 0;
    }

    @media screen and (max-width: 700px) {
      background-size: 80%;
      background-position: 220% 0;
    }

    @media screen and (max-width: 615px) {
      background-size: 90%;
      background-position: 204px 0;
    }

    @media screen and (max-width: 560px) {
      background-size: 100%;
      background-position: 147px 26px;
    }

    @media screen and (max-width: 460px) {
      background-size: 110%;
      background-position: 91px 25px;
    }

    @media screen and (max-width: 420px) {
      height: 275px;
      background-size: 123%;
      background-position: 50px 25px;
    }

    @media screen and (max-width: 390px) {
      height: 275px;
      background-size: 140%;
      background-position: 23px 0;
    }

    @media screen and (max-width: 370px) {
      height: 275px;
      background-size: 132%;
      background-position: 23px 63px;
    }
  }

  h2 {
    margin: 0 0 28px 0;
    font-size: 44px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #393939;

    @media screen and (max-width: 760px) {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
    }
  }

  button {
    margin-bottom: 320px;
  }
}
