.Button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;
  display: inline-block;
  background-image: linear-gradient(116deg, #000000 32%, #1d1d1d 74%);
  width: 190px;
  height: 56px;
  color: var(--white);
  font-size: 16px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  border: none;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s ease;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    display: inline-block;
    top: 0;
    left: 0;
    border: 1px solid var(--black);
    border-radius: 6px;
    width: 100%;
    height: 100%;
    background-color: transparent;

    transform: translate(4px, 4px);
    transition: all 0.2s ease;
  }

  &:hover {
    background-image: linear-gradient(116deg, #393838 32%, #1d1d1d 74%);
  }

  &:active {
    background-image: linear-gradient(116deg, #393838 32%, #1d1d1d 74%);
    transform: translate(4px, 4px);
  }

  &:active::after {
    transform: translate(0, 0);
  }
}

.orange {
  background-image: linear-gradient(
    116deg,
    var(--bright-orange) 32%,
    #f06000 74%
  );

  &::after {
    content: "";
    border: 1px solid var(--bright-orange);
  }

  &:hover,
  &:active {
    background-image: linear-gradient(116deg, #ff9955 32%, #f06000 74%);
  }
}

.borderless {
  cursor: pointer;
  padding: 0;
  border: 0;
  background: white !important;
  color: var(--bright-orange);
  height: 56px;
  font-size: 16px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  letter-spacing: 0.05em;

  &:focus,
  &:active {
    outline: none;
    border: none;
  }
}
