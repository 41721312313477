
.StripeElement {
    border-radius: 8px;
    border: solid 1px var(--brown-grey-30);
    padding: 0 18px;
    max-width: 238px;
    transition-duration: .4s;

  @media screen and (max-width: 776px) {
    max-width: 100%;
  }
}

.StripeElement--focus {
  border: 1px solid var(--bright-orange);
}

#StripeCVCElement {
  &.formGroup {
    margin: 0 auto 18px;
    max-width: 480px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 776px) {
      margin: 0 auto 23px;
    }

    & > div {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;

      p {
        padding-top: 16px;
        padding-left: 20px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: var(--black);
      }
    }

    label {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: var(--black);
    }
  }
  
  .input {
    padding: 0 16px;
    margin: 8px 0;
    height: 56px;
    line-height: 56px;
    display: block;
    width: 100%;
    border-radius: 8px;
    border: solid 1px var(--brown-grey-30);
  
    &:focus {
      border: solid 1px var(--bright-orange);
      outline: none;
    }
  }
  
  .inputLabel {
    span {
      color: var(--bright-orange);
    }
  }
  
  .inputErrorMessage {
    margin: 8px 0;
    font-size: 12px;
    color: red;
  }

  .cvcCaption {
    @media screen and (max-width: 776px) {
      display: none !important;
    }
  }
}
  
  