.GetTest {
  p:first-child {
    margin-top: 0;
    text-align: center;
  }
}

.testSteps {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 0;
  }
}

.testStep {
  text-align: center;
  font-weight: 300;

  &::before {
    content: "";
    color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    width: 56px;
    height: 56px;
    border: 4px solid var(--brown-grey-30);
    border-radius: 50%;
  }

  &:last-child {
    margin-left: 40px;
  }

  &:first-child::before {
    content: "1";
  }
  &:last-child::before {
    content: "2";
  }
}

.activeTestPage {
  &::before {
    border: 4px solid var(--bright-orange);
  }
}

.testType {
  padding: 38px 0 52px;
  background-color: var(--very-light-pink-30);
  margin-bottom: 64px;

  @media screen and (max-width: 600px) {
    padding: 27px 0 39px;
  }

  h4 {
    color: var(--bright-orange);
  }

  & > div > h4:nth-of-type(1) {
    margin: 0;
    margin-bottom: 32px;
  }

  & > div > h4:nth-of-type(2) {
    text-align: center;
    max-width: 430px;
    margin: 0 auto;
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: var(--bright-orange);
  }

  & > div > p:last-child {
    max-width: 531px;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;
  }

  .activeTestType {
    border: 2px solid var(--bright-orange);
  }
}

.testTypesCards {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.testTypesCard {
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid transparent;
  margin-bottom: 40px;
  width: 100%;

  &:last-of-type {
    margin: 0 0 40px 36px;

    @media screen and (max-width: 600px) {
      margin: 0 0 40px 0;
    }
  }
}

.cardText {
  flex-grow: 1;

  & > h4 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
  }
}

.cardPrice {
  border-left: solid 1px var(--brown-grey-30);
  flex-shrink: 1;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-left: 24px;

  @media screen and (min-width: 992px) {
    padding-left: 64px;
    height: 144px;
  }

  h2 {
    color: var(--bright-orange);
    margin: 0;

    @media screen and (min-width: 992px) {
      font-size: 44px;
      // line-height: 144px;
    }
  }

  span {
    font-size: 22px;
    line-height: 22px;
    color: var(--bright-orange);
  }
}

.accountDetails,
.testConfirm {
  h4 {
    margin: 0;
    padding-bottom: 16px;
    margin-bottom: 40px;
    color: var(--bright-orange);
    border-bottom: solid 1px var(--brown-grey-30);
  }
}

.testConfirm {
  p {
    margin: 0 auto;
    max-width: 480px;
    font-size: 12px;
    line-height: 18px;
  }

  button {
    display: block;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
  }
}