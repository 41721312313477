@import "../../styles/mixins.scss";

.Banner {
  margin-top: 64px;
  border-radius: 10px;
  background-color: #fef1e8;

  @include interpolate(height, 320px, 767px, 485px, 460px);

  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../../assets/images/be-a-hero-banner-mobile-image.png");
  background-position: right bottom;
  background-repeat: no-repeat;

  @include interpolate(background-size, 359px, 360px, 240px, 287px);

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../../assets/images/be-a-hero-banner-mobile-image@2x.png");
  }
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
    background-image: url("../../assets/images/be-a-hero-banner-mobile-image@3x.png");
  }

  @media screen and (min-width: 768px) {
    max-width: initial;
    height: initial;
    background-image: url("../../assets/images/be-a-hero-banner-image-no-shape.png");
    background-position: right center;
    @include interpolate(background-size, 768px, 1200px, 360px, 588px);

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("../../assets/images/be-a-hero-banner-image-no-shape@2x.png");
    }

    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
      background-image: url("../../assets/images/be-a-hero-banner-image-no-shape@3x.png");
    }
  }

  @media screen and (min-width: 992px) {
    height: 280px;
    background-size: 588px;
    background-position-x: 105%;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    text-align: left;
    margin: 0;
    margin-bottom: 16px;

    @media screen and (min-width: 992px) {
      font-size: 44px;
      color: var(--black);
      font-weight: 300;
    }
  }

  h4 {
    margin-top: 0;
    color: var(--bright-orange);
  }
}

.bannerContent {
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 32px;
    max-width: 50%;
  }

  @media screen and (min-width: 992px) {
    padding: 0 32px;
    width: 40%;
    flex-shrink: 0;
  }
}

.bannerImage {
  @media screen and (min-width: 992px) {
    flex-shrink: 1;
  }
}

.bannerSocialButtons {
  display: flex;
  justify-content: flex-start;

  button,
  a, 
  span {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;

      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  }
}
