.cancelledSubscription,
.confirmCancel {
  background-color: rgba(210, 210, 210, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  max-width: 494px;
  padding: 38px 20px 30px;
  margin: 0 auto;

  h2,
  p {
    margin: 0;
  }

  a {
    margin: 0 0 20px 0;
  }

  h2 {
    font-size: 27px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #000000;
    padding: 0 0 27px 0;

    @media screen and (max-width: 975px) {
      text-align: center;
    }
  }

  p {
    padding: 0 0 22px 0;

    @media screen and (max-width: 975px) {
      text-align: center;
    }
  }
}

.withoutSubscription {
  border-radius: 10px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 32px 25px 47px 40px;
  margin-top: 60px;

  @media (min-width: 320px) and (max-width: 975px) {
    flex-direction: column;
    align-items: center;
  }

  h2 {
    max-width: 357px;
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #000000;

    @media screen and (max-width: 975px) {
      max-width: 100%;
      text-align: center;
      padding: 0 0 25px 0;
    }
  }

  .contentWrapper {
    max-width: 468px;

    @media screen and (max-width: 1199px) {
      max-width: 400px;
    }

    @media screen and (max-width: 975px) {
      max-width: 100%;
      text-align: center;
    }

    p {
      margin: 0;
      padding: 0 0 25px 0;
    }
  }
}

.withoutSubscription,
.cancelledSubscription,
.confirmCancel {
  margin: 35px auto 0;
}