.prevCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 25px 0;

  .prevCardWrapper {
    margin: 0 15px 0 0;
    border-radius: 10px;
    border: 1px solid var(--brown-grey-30);
    padding: 15px;
    display: flex;

    div {
      &:last-of-type {
        margin: 0 0 0 10px;
        padding: 0 0 0 10px;
        border-left: 1px solid var(--brown-grey-30);
      }
    }
  }

  svg {
    cursor: pointer;
  }
}

.cardError {
  padding: 0 0 25px 0;
  margin: -35px 0 0 0;
  color: #ff0202;
  font-size: 14px;
  font-family: "Roboto Slab", serif;
  display: flex;
  justify-content: center;
}
