@keyframes infinityRotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.small {
  display: flex;
  justify-content: center;
  align-items: center;
}

.large {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1010;
}

.modalOverlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
}

.loaderImage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px;
  svg {
    animation-duration: 3s;
    animation-name: infinityRotation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}
