.BillingForm {
  padding-top: 22px;
  margin-bottom: 56px;

  button {
    display: block;
    margin: 0 auto 24px;

    @media screen and (max-width: 776px) {
      margin: 0 auto 15px;
    }
  }
}

.accountNav {
  margin-bottom: 48px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
}

.buttonsWrapper[data-style="no-banner"] {
  margin: 0 auto 122px;

  @media screen and (max-width: 776px) {
    padding: 14px 0 10px 0;
    margin: 0 auto;
    flex-direction: column-reverse;
  }
}

.prevCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 25px 0;
  margin: -6px 0 0 0;

  .prevCardWrapper {
    margin: 0 15px 0 0;
    border-radius: 10px;
    border: 1px solid var(--brown-grey-30);
    padding: 15px;
    display: flex;

    div {
      &:last-of-type {
        margin: 0 0 0 10px;
        padding: 0 0 0 10px;
        border-left: 1px solid var(--brown-grey-30);
      }
    }
  }

  svg {
    cursor: pointer;
  }
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  span {
    padding: 0 0 25px 0;
    margin: -35px 0 0 0;
    color: #ff0202;
    font-size: 14px;
    font-family: "Roboto Slab", serif;
  }
}
