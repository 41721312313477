.LayoutHeader {
  padding: 24px;
  padding-bottom: 32px;
  background-image: linear-gradient(84deg, #ef884b 29%, #f6975e 82%);

  @media screen and (min-width: 1200px) {
    padding-bottom: 100px;
  }
}

.LayoutContent {
  padding: 24px;
  max-width: 1024px;

  @media screen and (min-width: 576px) {
    padding: 40px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 64px;
    margin: 0 auto;
  }

  // @media screen and (min-width: 992px) {
  //   padding: 40px 64px;
  //   margin: 0 auto;
  // }

  @media screen and (min-width: 1200px) {
    padding: 40px 0;
    margin: 0 auto;
  }
}
