.footer {
  padding: 63px 91px 45px;
  background-color: var(--black);
  color: white;
  font-weight: 300;
  margin-top: 73px;
  position: relative;

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 36px 24px 21px;
  }

  @media screen and (min-width: 767px) {
    margin-top: 133px;
  }

  &.geometry {
    &::before {
      content: "";
      position: absolute;
      top: -383px;
      left: 0;
      width: 100%;
      height: 383px;
      background-color: rgba(255, 103, 2, 0.1);
      clip-path: ellipse(100% 84% at 0% 160%);
  
      @media screen and (min-width: 768px) {
        top: -721px;
        width: 80%;
        height: 721px;
        clip-path: ellipse(100% 80% at 0% 160%);
      }

      @media screen and (min-width: 1200px) {
        top: -1021px;
        width: 70%;
        height: 1021px;
        clip-path: ellipse(100% 80% at 0% 160%);
      }
    }
  }

  &::after {
    // margin-top: 200px;
    content: "";
    position: absolute;
    top: -200px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: rgba(255, 103, 2, 0.5);
    clip-path: ellipse(100% 80% at 0% 160%);

    @media screen and (min-width: 768px) {
      // margin-top: 280px;
      top: -280px;
      width: 80%;
      height: 280px;
      clip-path: ellipse(100% 80% at 0% 160%);
    }

    @media screen and (min-width: 1200px) {
      width: 70%;
      clip-path: ellipse(100% 80% at 0% 160%);
    }
  }
}

.footerContent {
  @media screen and (min-width: 768px) {
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
  }

  & > :last-child {
    display: flex;
    // justify-content: flex-end;
    margin-top: 5px;
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
      margin: initial;
      align-self: flex-start;
      order: -1;
      flex: 1 1 100%;
    }
  }
}

.footerRight {
  @media screen and (min-width: 768px) {
    display: flex;
    flex: 1 1 100%;
  }

  & > a {
    margin-top: 28px;
    margin-bottom: 53px;

    @media screen and (min-width: 992px) {
      display: initial;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 64px;
    }
  }
}

.footerLinks {
  border-bottom: 2px solid var(--bright-orange);

  @media screen and (min-width: 768px) {
    display: flex;

    & > div {
      margin-right: 40px;
      min-width: 160px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.footerCol {
  margin-bottom: 40px;
  padding-top: 40px;
  border-top: 2px solid var(--bright-orange);

  @media screen and (min-width: 768px) {
    padding-top: 18px;
  }

  &:first-child {
    padding-top: 0;
    border: none;

    @media screen and (min-width: 768px) {
      padding-top: 18px;
      border-top: 2px solid var(--bright-orange);
    }
  }

  &:last-child {
    margin: 0 0 32px 0;
  }

  li {
    margin-bottom: 18px;
  }

  a {
    font-size: 16px;
    color: white;
    text-decoration: none;
  }
}

.copywright {
  margin-top: 48px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.socialWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 16px 0 0 0;

  @media screen and (max-width: 768px) {
    padding: 34px 0 0 0;
    max-width: 328px;
  }

  .footerCol {
    border: none;
  }
}

.iconsWrapper {
  display: flex;
  width: 60px;
  justify-content: space-between;
  padding: 18px 0 0 0;

  @media screen and (max-width: 768px) {
    align-items: start;
    padding: 0;
    margin: -4px 0 0 0;
  }
}

.footerLogoWrapper {
  a {
    display: flex;
  }
}

.footer[data-style='withoutEllipse'] {
  margin-top: 0;

  &::after {
    display: none;
  }
}
