.reset {
  background-color: #fe9146;
  padding: 0 0 97px 0;
  background-image: url('../../assets/images/keyline-shape@3x.png');
  background-size: cover;
  background-position: 182px 88%;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    background-image: unset;
  }

  @media screen and (max-width: 850px) {
    padding: 0 0 112px 0;
  }
}

.loginHeader {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;

  @media screen and (min-width: 1280px) {
    padding: 24px 0;
  }

  a {
    display: inline-block;
  }
}

.content {
  background-color: #fff;
  border-radius: 10px;
  max-width: 800px;
  padding: 59px 95px 70px 95px;
  margin: 70px auto 0;

  @media screen and (max-width: 850px) {
    border-radius: 10px;
    background-color: #fff;
    padding: 0 31px 31px 31px;
    margin: 18px 13px 0 13px;

  }

  form {
    display: block;
    max-width: 480px;
    margin: 40px auto;

    @media screen and (max-width: 850px) {
      margin-top: 20px;
    }
  }

  h2 {
    font-size: 44px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
    font-family: Roboto;
    font-weight: 400;
    padding: 0 0 18px 0;

    @media screen and (max-width: 850px) {
      padding: 28px 0 0 0;
      font-size: 32px;
      margin-bottom: 0;
    }
  }

  h2 + p {
    color: var(--black);
    text-align: center;
    margin: 0 auto;
    max-width: 440px;
  }

  button {
    display: block;
    margin: 48px auto 35px;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: var(--white);
    height: 53px;
    width: 180px;
  }

  a:last-child {
    display: block;
    margin: 32px auto 0;
  }
}

.resetWrapper {
  max-width: 494px;
  margin: 0 auto;
}

.title {
  font-size: 44px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.64;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
  padding: 14px 0 28px 0;
  margin: 0;

  @media screen and (max-width: 850px) {
    padding: 42px 0 26px 0;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.69;
  }
}

.progressBarTitle {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;
  padding: 0 0 2px 0;
}

.progressBar {
  height: 10px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px var(--brown-grey-30);

  .progressLine {
    height: 8px;
    border-radius: 10px;
    transition-duration: 0.4s;
  }
}

.errorText {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 30px 0 0 0;
}

.buttonWrapper {
  button {
    margin: 28px auto 35px;
  }
}
