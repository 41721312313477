.formGroup {
  margin: 0 auto 18px;
  max-width: 480px;
}

label {
  @media screen and (max-width: 850px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--black);
  }
}

.container {
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    color: var(--brown-grey-30);
  }
}

.inputPhone,
.input {
  padding: 0 16px;
  margin: 0;
  height: 59px;
  line-height: 56px;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: solid 1px var(--brown-grey-30);
  font-size: 14px;

  &:focus {
    border: solid 1px var(--bright-orange);
    outline: none;
  }
}

.inputPhone {
  padding: 0 16px 0 42px;
}

.inputLabel {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--black);

  span {
    color: var(--black);
  }
}

.inputErrorMessage {
  margin: 8px 0 !important;
  font-size: 12px;
  color: red;
}
