.PageHeading {
  padding: 48px 0 0;

  &[data-style='TestOnWay'] {
    @media screen and (max-width: 576px) {
      max-width: 214px;
    }
  }

  color: var(--black);
  text-align: center;
  margin: 0 auto;
  // max-width: 200px;

  @media screen and (min-width: 576px) {
    max-width: initial;
  }

  @media screen and (min-width: 1200px) {
    font-size: 44px;
    transform: translateY(-50px);
    background-color: white;
    max-width: 1100px;
    border-radius: 10px;
  }

  h2 {
    font-size: 44px;
    margin: 0;
    margin-bottom: 16px;

    @media screen and (max-width: 576px) {
      font-size: 32px !important;
      margin: -18px 0 16px 0 !important;
    }
  }

  p {
    text-align: center;
    margin: 0;
  }
}
