.message {
  position: fixed;
  bottom: 30px;
  right: 0;
  transform: translateX(100%);
  max-width: 600px;
  z-index: 10000;
  padding: 10px 25px;
  border-radius: 5px;
  color: #fff;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  font-size: 16px;
  background: linear-gradient(116deg, var(--bright-orange) 32%, #f06008 74%);
  transition: transform 0.4s;
}

.active {
  transform: translateX(-15%);
}
