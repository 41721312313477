@import "../../styles/mixins.scss";

.accountForms {
  form {
    margin: 0 auto;
    max-width: 480px;
  }

  h3 {
    margin-bottom: 24px;
    text-align: center;
    font-weight: 300;
    color: var(--black);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;

    @media screen and (min-width: 320px) {
      text-align: center;
      margin-bottom: 17px;
    }

    @media screen and (min-width: 776px) {
      text-align: center;
      margin: 0 0 5px 0;
    }
  }

  button {
    display: block;
    margin: 42px auto 0;
  }
}

.expirationDate {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;

  @media screen and (min-width: 1200px) {
    transform: translateY(-50px);
    margin-bottom: -28px;
  }
}

.accountNav {
  max-width: 1024px;
  padding: 0 24px;
  overflow-x: scroll;
  border-bottom: solid 1px var(--brown-grey-30);
  display: flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  margin: 38px auto 46px;
  justify-content: space-between;

  @media screen and (min-width: 800px) {
    padding: initial;
    justify-content: center;
    overflow-x: initial;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 16px;
  }
}

.navItem {
  flex-shrink: 0;
  height: 48px;
  padding: 0 24px;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  background-color: var(--very-light-pink-30);
  color: var(--bright-orange);
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 48px;
  font-weight: 500;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: all 0.2s ease;

  &:not(:last-child) {
    margin-right: 16px;

    @media screen and (min-width: 800px) {
      margin-right: 46px;
    }
  }
}

.selected {
  background-color: var(--bright-orange);
  color: white;
}

.prevPayments {
  margin-top: 66px;

  h3 {
    @media screen and (min-width: 320px) {
      margin-bottom: 22px;
    }

    @media screen and (min-width: 776px) {
      margin: 0 0 40px 0;
    }
  }

  @media screen and (max-width: 776px) {
    margin-top: 0;
  }
}

.prevPayment {
  max-width: 360px;
  margin: 0 auto;
  font-weight: 300;
  color: #000000;
  border: solid 1px var(--brown-grey-30);
  border-radius: 10px;
  padding: 7px 24px 1px 24px;
  margin-bottom: 32px;

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: solid 1px var(--brown-grey-30);
    padding: 10px 0;

    &:first-child {
      @media screen and (max-width: 576px) {
        padding: 10px 0;
      }
    }

    @media screen and (max-width: 576px) {
      padding: 13px 0 10px 0;
    }

    &:last-child {
      border: none;
    }

    span {
      font-weight: 400;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {
    @include interpolate(font-size, 576px, 768px, 14px, 16px);
  }

  @media screen and (min-width: 576px) {
    margin-bottom: 0;
    padding: 23px 0 20px 0;
    border-radius: initial;
    border: none;
    border-bottom: solid 1px var(--brown-grey-30);
    max-width: initial;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    div {
      display: initial;
      border-bottom: none;
      padding: initial;
    }

    span {
      display: none;
    }
  }

  &:first-child {
    display: none;
    font-weight: 400;
    border-top: solid 1px var(--brown-grey-30);
    border-bottom: solid 2px var(--bright-orange);

    @media screen and (min-width: 576px) {
      display: grid;
      padding: 16px 0 14px 0;
    }
  }
}
