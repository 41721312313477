.testimonialsCards {
  padding-left: 24px;
  display: grid;
  grid-template-columns: repeat(3, calc(100vw - 64px));
  grid-column-gap: 16px;

  @media screen and (min-width: 440px) {
    width: 1024px;
    padding-left: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    padding-right: 24px;
    margin-left: 24px;
  }

  @media screen and (min-width: 768px) {
    padding-right: 40px;
    margin-left: 40px;
    grid-column-gap: 32px;
  }

  @media screen and (min-width: 1064px) {
    padding-right: 0;
  }

  @media screen and (min-width: 1104px) {
    margin: 0 auto;
    padding-right: 0;
  }

  @media screen and (min-width: 1200px) {
    width: 1100px;
  }

  margin-bottom: 48px;
}

.testimonialsCard {
  padding: 39px 24px 25px 24px;
  border-radius: 10px;
  background-color: rgba(210, 210, 210, 0.2);
  width: calc(100vw - 64px);

  @media screen and (min-width: 320px) {
    width: auto;
  }

  &::before {
    content: "“";
    display: block;
    text-align: left;
    color: var(--bright-orange);
    font-size: 60px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 40px;
  }

  p::after {
    content: "";
    margin-top: 18px;
    display: block;
    width: 80px;
    height: 7px;
    background-color: var(--bright-orange);
  }

  h4 {
    margin: 0;
    padding: 0;
  }
}

*:focus {
  outline: 0;
  outline: none;
}

.cardWrapper {
  padding: 0 10px;
}