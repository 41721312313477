@import "./styles/variables.css";
@import "./styles/typography.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500&family=Roboto:wght@300;400;500;700&display=swap");

:root {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  padding: 0 24px;
  max-width: 1024px;

  @media screen and (min-width: 576px) {
    padding: 0 40px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 0 64px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    padding: 0;
    margin: 0 auto;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  display: block;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  // color: var(--black);
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  // padding: 0 24px;
  padding: 0;
  margin: 0;
  position: relative;
  display: inline-block;
  background-image: linear-gradient(116deg, #000000 32%, #1d1d1d 74%);
  width: 190px;
  // min-width: 190px;
  height: 56px;
  color: var(--white);
  font-size: 16px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  border: none;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s ease;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0;
    border-right: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
    border-radius: 6px;
    width: 100%;
    height: 100%;
    background-color: transparent;

    transform: translate(4px, 4px);
    transition: all 0.2s ease;
  }

  &:hover {
    background-image: linear-gradient(116deg, #393838 32%, #1d1d1d 74%);
  }

  &:active {
    background-image: linear-gradient(116deg, #393838 32%, #1d1d1d 74%);
    transform: translate(4px, 4px);
  }

  &:active::before {
    transform: translate(0, 0);
  }
}

.error {
  text-align: center;
  color: #df0000;
}

.btn-orange {
  background-image: linear-gradient(
    116deg,
    var(--bright-orange) 32%,
    #f06000 74%
  );

  &::before {
    border: 0;
    border-right: 1px solid var(--bright-orange);
    border-bottom: 1px solid var(--bright-orange);
  }

  &:hover,
  &:active {
    background-image: linear-gradient(116deg, #ff9955 32%, #f06000 74%);
  }
}

.btn-borderless {
  cursor: pointer;
  padding: 0;
  border: 0;
  background: white !important;
  color: var(--bright-orange);
  height: 56px;
  font-size: 16px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  letter-spacing: 0.05em;

  &:focus,
  &:active {
    outline: none;
    border: none;
  }
}
.btn-greyActive,
.btn-grey {
  background: var(--brown-grey-30);

  &::before {
    border: 0;
    border-right: 1px solid var(--brown-grey-30);
    border-bottom: 1px solid var(--brown-grey-30);
  }

  &:hover,
  &:active {
    background: rgba(151, 151, 151, 0.6);
  }
}

.btn-greyActive {
  color: var(--black);
  line-height: 18px;
  font-weight: 400;
}


.container {
  .slick-list {
    overflow: visible !important;
  }
}