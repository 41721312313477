.TestOnWay {
  & > div > a:last-child {
    display: block;
    margin: 90px auto 275px;

    @media screen and (max-width: 776px){
      display: none;
    }
  }
}

.TestOnWaySubTitle {
  color: var(--bright-orange);
  padding: 5px 0 13px 0;
  margin-bottom: 0;
  border-bottom: 1px solid var(--brown-grey-30);

  @media screen and (max-width: 776px){
    padding: 5px 0 10px 0;
  }
}

.TestOnWayHomeLink {
  display: block;
  width: 190px;
  margin: 48px auto;
}
