.trustedWorldwideBigNumbers {
  overflow: hidden;
  max-width: 1024px;
  margin: 32px auto 58px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (min-width: 320px) {
    margin: 0 auto;
  }

  @media screen and (min-width: 890px) {
    padding: 36px 64px 65px 64px;
  }

  @media screen and (max-width: 990px) {
    display: block;
    padding: 0 50px 20px 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 36px 0 65px 0;
  }
}

.trustedWorldwideBigItem {
  @media screen and (max-width: 990px) {
    padding: 0 25px;
  }

  & > div {
    font-size: 54px;
    font-weight: 700;
    color: var(--bright-orange);
    padding-bottom: 8px;
    border-bottom: 7px solid var(--bright-orange);
    min-height: 125px;
    display: flex;
    align-items: flex-end;
  }

  p {
    margin-top: 16px;
    font-size: 28px;
    font-weight: 400;
    color: var(--bright-orange);
  }

  &:not(:last-child) {
    margin-right: 64px;
  }

  &:nth-of-type(even) > div {
    font-size: 94px;
  }
}

*:focus {
  outline: 0;
  outline: none;
}