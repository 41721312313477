.accordion {
}

.accordionItem {
  border-bottom: solid 1px var(--brown-grey-30);

  @media screen and (max-width: 760px) {
    &:last-child {
      border-bottom: none;
    }
  }
}

.accordionButton {
  cursor: pointer;
  height: 80px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: var(--black);
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 0 0;
}

.accordionItem:first-of-type {
  .accordionButton {
    align-items: flex-start;
    height: 46px;

    @media screen and (max-width: 776px) {
      height: 80px;
      padding: 29px 0 0 0;
    }
  }

    svg {
      margin: 0;
    }
}


.accordionPanel {
  padding-bottom: 24px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;

  animation: fadeIn 0.5s ease;

  p {
    margin: 0;
    padding-right: 24px;

    a {
      display: inline;
      color: var(--bright-orange);
    }
  }
}

.expandedPanel {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
