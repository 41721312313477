.ChangePasswordForm {
  margin: -7px 0 0 0;
}

.errorText {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 30px 0 0 0;
}
