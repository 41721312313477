.StepsContainer {
  border-bottom: 1px solid var(--brown-grey-30);

  @media screen and (max-width: 892px) {
    margin: 0 0 -37px 0;
  }

  @media screen and (max-width: 800px) {
    padding-bottom: 40px;
  }

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-column-gap: 40px;
    padding: 0 0 75px 0;
  }
}

.packagingImg {
  position: relative;
  width: 571px;
  height: 391px;

  @media screen and (max-width: 992px) {
    width: initial;
    height: initial;
  }

  img {
    position: absolute;
    bottom: -23px;
    left: 0;

    @media screen and (max-width: 992px) {
      position: static;
      bottom: 0;
    }
  }
}

.Step {
  border-bottom: 1px solid var(--brown-grey-30);
  margin-bottom: 0;
  text-align: center;
  padding: 20px 0;

  &:last-child {
    margin: 0 0 60px 0;
    border-bottom: none;

    @media screen and (max-width: 992px) {
      padding: 40px 0 0 0;
    }

    @media screen and (min-width: 892px) {
      margin: 0;
    }
  }

  &:first-child {
    @media screen and (max-width: 992px) {
      padding: 0 0 40px 0;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 40px 0;
  }

  @media screen and (min-width: 576px) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 300;
    line-height: 28px;
    width: 200px;
    margin: 4px auto 0;

    @media screen and (min-width: 576px) {
      margin: 0 0 0 16px;
      width: initial;
      text-align: left;
    }
  }

  svg {
    @media screen and (min-width: 576px) {
      max-width: 32px;
      max-height: 32px;
    }
  }
}

.StepsContainer[data-style="true"] {
  .Step {
    &:first-child {
      @media screen and (max-width: 992px) {
        padding: 40px 0 40px 0;
      }
    }
  }
}
