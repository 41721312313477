 .AccountDetailsForm {
   h3 {
     font-size: 24px;
     font-weight: 300;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.42;
     letter-spacing: normal;
     text-align: center;
     color: #000000;
     padding: 0 0 12px 0;
   }
 }

.formTextInfo {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  max-width: 300px;
  text-align: center;
  margin: 0 auto 32px;
  padding: 7px 0 35px 0;
}
