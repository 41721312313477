.header {
  overflow-x: hidden;
  position: relative;
  z-index: 777;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1800px) {
    max-width: 1200px;
  }
}

.mobileMenu {
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    display: none;
  }

  & > div {
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
}

.headerNav {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  a {
    color: white;
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto Slab", serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-left: 40px;
  }
}

// .activeLink {
  // padding-bottom: 12px;
  // border-bottom: 2px solid white;
// }

.headerBtn {
  height: 40px;
  line-height: 40px;
  // background-color: rgba(255, 255, 255, 0.36);
  background-color: #f8ba94;
  border: none;
  outline: none;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  color: #f07320;
  font-weight: 500;
  padding: 0 16px;
  margin-left: 40px;
  letter-spacing: 0.05em;
  font-size: 16px;
  color: white;

  &:hover {
    // background-color: rgba(255, 255, 255, 0.25);
    background-color: #f8ba94a9
  }
}

.mobileMenuContainer {
  position: fixed;
  z-index: 9999;
  transform: translateX(100%);
  top: 0;
  bottom: 0;
  right: 0;
  width: 210px;
  height: 100vh;
  padding: 120px 24px 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
  transition: transform 0.2s ease;

  @media screen and (min-width: 768px) {
    display: none;
  }

  & > svg {
    position: absolute;
    top: 32px;
    right: 24px;
    cursor: pointer;
  }

  & button,
  & > a:last-child {
    margin-top: 24px;
    display: inline-block;
    margin-left: 0 !important;
    color: var(--bright-orange);
  }
}

.menuOpened {
  transform: translateX(0);
}

.mobileMenuNav {
}

.mobileMenuLink {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 500;
  color: #f07320;
  text-transform: uppercase;
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid var(--brown-grey-30);
  text-align: left;

  &:hover {
    color: var(--black);
  }
}

.mobileMenuLinkActive {
  color: var(--black);
}
