#cardExpiryDate {
  .StripeElement {
      border-radius: 8px;
      border: solid 1px var(--brown-grey-30);
      padding: 0 18px;
      max-width: 100%;
      transition-duration: .4s;
  }

  .StripeElement--focus {
    border: 1px solid var(--bright-orange);
  }

  &.formGroup {
    margin: 0 auto 44px;
    max-width: 480px;

    @media screen and (max-width: 776px) {
      margin: 0 auto 26px;
    }

      & > div {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;

        p {
          padding-top: 28px;
          padding-left: 20px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--black);
        }
      }

      label {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: var(--black);
      }
    }
    
    .input {
      padding: 0 16px;
      margin: 8px 0;
      height: 56px;
      line-height: 56px;
      display: block;
      width: 100%;
      border-radius: 8px;
      border: solid 1px var(--brown-grey-30);
    
      &:focus {
        // border: solid 1px var(--black);
        border: solid 1px var(--bright-orange);
        outline: none;
      }
    
      // &:invalid {
      //   border: 1px solid red;
      // }
    }
    
    .inputLabel {
      span {
        color: var(--bright-orange);
      }
    }
    
    .inputErrorMessage {
      margin: 8px 0;
      font-size: 12px;
      color: red;
    }
}

