// .Login {

// }

.loginButtons {
  text-align: center;

  button {
    display: block;
    margin: 48px auto 32px;
  }

  a {
    display: initial;
    color: var(--black);
  }
}
