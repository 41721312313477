.headerDateWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px var(--brown-grey-30);
  margin: 50px 0 48px 0;
  align-items: center;
  height: 57px;

  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
  }

  @media screen and (max-width: 992px) {
    margin: 0 0 30px 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px 0;
  }
}

.subtitle {
  text-align: left;
  color: var(--bright-orange);

  @media screen and (max-width: 992px) {
    margin: 12px 0 15px 0;
  }
}

.resultsDate {
  font-weight: 300;
}

.resultContainer {
  padding: 24px;
  border-radius: 10px;
  background-color: rgba(210, 210, 210, 0.2);
  margin-bottom: 60px;

  @media screen and (min-width: 992px) {
    padding: 40px 25px 40px 40px;
    display: flex;
    justify-content: space-between;
  }
}

.result {
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  flex: 1 1 90%;
  margin-bottom: 20px;

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
    margin-right: 80px;
    justify-content: flex-start;
  }
}

.info {
  h4 {
    margin-top: 0;
    max-width: 293px;
    margin-bottom: 24px;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
  }
}

.qfit {
  margin-bottom: 4px;

  span {
    font-weight: 400;
  }
}

.colorBox {
  width: 112px;
  height: 112px;
  flex-basis: 112px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    min-height: 100%;
    min-width: 100%;
  }

  @media screen and (min-width: 992px) {
    order: -1;
    margin-right: 32px;
  }
}

.resultText {
  margin: 0;
  line-height: 28px;
  flex: 1 1 100%;
  font-family: 'Roboto-Light';
  letter-spacing: 0;

  @media screen and (min-width: 992px) {
    max-width: 492px;
  }
}

.resultBanner {
  margin-bottom: 20px;

  @media screen and (min-width: 992px) {
    margin: 0;
  }

  h4 {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #000000;
  }
}

.resultTextBanner {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;
  max-width: 361px;
  display: flex;
  flex-direction: column;
  margin: 0;

  a {
    margin: 18px 0 0 0;
  }
}
