.logo {
  // height: 48px;

  text-align: center;
  font-size: 19px;
  line-height: 48px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;

  span {
    display: inline-block;
    width: 130px;
  }

  span:nth-child(1) {
    border: solid 2px var(--black);
    color: var(--black);
  }

  span:nth-child(2) {
    display: none;

    @media screen and (min-width: 992px) {
      display: inline-block;
      border: solid 2px transparent;
      border-right: solid 2px var(--white);
      color: var(--white);
    }
  }
}

.logoFooter {
  span:nth-child(1) {
    border: solid 2px var(--white);
    color: var(--white);
  }

  span:nth-child(2) {
    display: inline-block;
    border: solid 2px transparent;
    border-right: solid 2px var(--white);
    color: var(--white);
  }
}
