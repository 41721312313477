// .Homepage {
//   position: relative;
// }

.hero {
  background: #fe9146;
  background-blend-mode: multiply;
  padding: 18px 50px;

  @media screen and (min-width: 1200px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    overflow: hidden;
    padding: 18px 25px;
  }
}

.heroDescription {
  padding: 0 84px 0 0;

  @media screen and (max-width: 1200px) {
    padding: 0 50px 0 0;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
  }

  h3 {
    margin: 0;
  }
}

.heroContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
  margin: 10px 0 0 0;

  @media screen and (max-width: 1200px) {
    margin: 10px 0 40px 0;
  }

  @media screen and (max-width: 1060px) {
    align-items: center;
    justify-content: stretch;
    flex-direction: column;
    margin: 10px 0 40px 0;
  }

  h3 {
    color: var(--white);
    margin-bottom: 32px;
    max-width: 414px ;

    @media screen and (max-width: 1060px) {
      padding: 0 0 30px 0;
      max-width: 100%;
    }

    p {
      font-weight: 300;
      margin: 0.5em 0;
      font-size: 24px;
      line-height: 1.33;
    }
  }

  button {
    z-index: 1;
  }
}

.heroImage {
  flex-shrink: 0;

  @media screen and (max-width: 1060px) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 0 0 20px 0;
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    top: -93px;
    left: 0;
    width: 617px;
    height: 521px;
    background: url("../../assets/images/homepage-header-image-in-shape.png")
        center no-repeat,
      #fe9146;
    background-blend-mode: multiply;
    background-size: contain;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("../../assets/images/homepage-header-image-in-shape@2x.png");
    }
    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
      background-image: url("../../assets/images/homepage-header-image-in-shape@3x.png");
    }
  }

  h2 {
    font-family: Roboto;
    font-size: 60px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white);
    margin: 215px 0 0 135px;

    @media screen and (max-width: 1200px) {
      margin: 100px 0 0 120px;
    }

    @media screen and (max-width: 1060px) {
      margin: 30px 0 0 0;
    }

    @media screen and (max-width: 600px) {
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: normal;
    }

    span {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 215px;
        height: 7px;
        background-color: var(--bright-orange);
        bottom: 0;
        left: 0;

        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }
  }
}

.heroText {
  display: flex;
  margin: 80px 0 0 95px;

  a {
    @media screen and (max-width: 600px) {
      width: 180px;
    }
  }

  @media screen and (max-width: 1271px) {
    margin: 40px 0 0 50px;
  }

  @media screen and (max-width: 1199px) {
    margin: 80px 0 0 95px;
  }

  @media screen and (max-width: 1060px) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 50px 0 0 0;
  }

  @media screen and (max-width: 600px) {
    margin: 6px 0 0 0;
  }
}

.priceCircle {
  position: relative;
  width: 121px;

  @media screen and (max-width: 1060px) {
    display: flex;
    align-items: flex-end;
  }

  @media screen and (max-width: 470px) {
    width: 0;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 121px;
    height: 121px;
    background-image: url("../../assets/images/just-25-a-test@3x.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -23px;

    @media screen and (max-width: 1060px) {
      top: unset;
      bottom: -28px;
      left: -89px;
    }

    @media screen and (max-width: 470px) {
      width: 114px;
      height: 121px;
    }

    @media screen and (max-width: 340px) {
      left: -70px;
    }
  }
}

.howItWorks {
  padding-bottom: 0;
  margin-bottom: 64px;
  text-align: center;

  @media screen and (max-width: 760px) {
    margin-bottom: 98px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
    transform: translateY(-50px);
    padding: 22px 0;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: var(--bright-orange);
    margin: 40px auto 24px;
    text-align: left;

    @media screen and (max-width: 760px) {
      margin: 40px auto 29px;
      text-align: center;
    }

    @media screen and (min-width: 1200px) {
      margin: 0;
      padding: 15px 0 25px 0;
    }
  }

  .howItWorksItem {
    border-bottom: 1px solid var(--brown-grey-30);
    margin-bottom: 48px;

    &:nth-child(6) {
      border-bottom: none;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 4.38;
      letter-spacing: normal;
      color: #000000;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
      margin-bottom: 40px;
    }
  }

  .packaging {
    padding-bottom: 48px;
    border-bottom: 1px solid var(--brown-grey-30);
  }
}

.trustedWorldwide {
  overflow-x: hidden;
  text-align: left;
  padding-bottom: 20px;

  @media screen and (max-width: 550px) {
    padding-bottom: 10px;
  }

  h2 {
    margin: 0 0 24px 0;
    color: var(--black);
  }

  p {
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 48px;
  }

  h4 {
    margin: 0;
    color: var(--bright-orange);
  }
}

.trustedWorldwideContainer {
  margin-bottom: 80px;

  @media screen and (max-width: 760px) {
    margin-bottom: 50px;
  }

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-column-gap: 48px;
  }
}

.trustedWorldwideText {
  grid-row: 1/2;
  grid-column: 2 / 3;

  h2 {
    font-family: "Roboto";
    font-size: 44px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #323232;

    @media screen and (max-width: 760px) {
      font-size: 32px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      max-width: 350px;
    }
  }

  p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;

    a {
      display: inline;
      color: var(--bright-orange);
    }
  }
}

.trustedWorldwideImage {
  grid-row: 1/2;
  grid-column: 1 / 2;

  position: relative;
  left: -15vw;
  width: 120vw;

  @media screen and (min-width: 440px) {
    position: initial;
    left: initial;
    width: initial;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 992px) {
    margin-top: 64px;
    text-align: center;
  }
}

.trustedWorldwideSmallNumbers {
  display: flex;
  margin-bottom: 48px;
}

.trustedWorldwideSmallItem {
  &:last-child {
    margin-left: 48px;
  }

  & > div {
    display: inline-block;
    font-size: 44px;
    font-weight: 300;
    color: var(--bright-orange);
    border-top: 7px solid var(--bright-orange);
    padding: 16px 0;
  }

  p {
    margin: 0;
    line-height: 22px;
  }
}

.trustedWorldwideImg {
  width: 140%;
  position: relative;
  left: -15%;
  margin-bottom: 40px;
}

.bowelCancer {
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}

.bowelCancerText {
  @media screen and (min-width: 768px) {
    max-width: 80%;
  }

  @media screen and (min-width: 992px) {
    width: 50%;
    max-width: 480px;
  }

  a {
    @media screen and (min-width: 768px) {
      flex-shrink: 0;
    }
  }

  p {
    @media screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

.story {
  position: relative;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 100px;
  margin-bottom: 64px;

  @media screen and (max-width: 760px) {
    padding-top: 25px;
    margin-bottom: 54px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 103, 2, 0.1);
    // clip-path: ellipse(180% 80% at 20% 80%);

    @media screen and (min-width: 320px) {
      clip-path: ellipse(250% 50% at 0% 50%);
    }

    @media screen and (min-width: 768px) {
      clip-path: ellipse(120% 50% at 0% 50%);
    }

    @media screen and (min-width: 992px) {
      clip-path: ellipse(110% 80% at 10% 80%);
    }
  }

  & > div {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      align-items: center;
    }

    @media screen and (min-width: 992px) {
      grid-template-columns: 2.5fr 3fr;
      grid-column-gap: 40px;
    }
  }
}

.storyContent {
  padding: 15px 0 0 0;

  h4 {
    color: var(--bright-orange);

    @media screen and (max-width: 760px) {
      margin-bottom: 0;
    }
  }

  h1,
  h2 {
    color: var(--black);
    font-size: 44px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
  }

  h2 {
    font-size: 32px;
    line-height: 1.28;
    max-width: 458px;
  }

  h1 {
    margin: 0;
    padding: 5px 0 0 0;
  }

  p {
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 42px;

    @media screen and (max-width: 760px) {
      margin-top: 35px;
      margin-bottom: 30px;
    }
  }
}

.storyImg {
  position: relative;
  left: -15vw;
  width: 115vw;
  padding: 64px 0 0;

  @media screen and (max-width: 440px) {
    position: initial;
    left: initial;
    width: initial;

    img {
      display: block;
      margin: 0 0 0 -55px;
      max-width: 140%;
    }
  }

  @media screen and (min-width: 440px) {
    position: initial;
    left: initial;
    width: initial;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 768px) {
    padding-top: 0;
  }
}

.testimonials {
  padding: 20px 0 46px 0;
  overflow: hidden;

  @media screen and (max-width: 760px) {
    padding: 0;
  }

  h4 {
    margin-top: 0;
    color: var(--bright-orange);
    padding: 0 0 23px 0;

    @media screen and (max-width: 760px) {
      padding: 0;
    }
  }

  p,
  span {
    line-height: 28px;
    font-weight: 300;
  }

  & + div > a {
    display: block;
    margin: 0 auto;
  }
}

.faq {
  margin-top: 48px;
  padding: 8px 0 0 0;

  & > div {
    padding-top: 85px;
    border-top: solid 1px var(--brown-grey-30);

    @media screen and (max-width: 760px) {
      padding-top: 30px;
    }

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-column-gap: 24px;
    }

    @media screen and (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 0;
    }
  }

  h2 {
    margin-top: 0;
    font-size: 44px;

    @media screen and (max-width: 760px) {
      margin: 0;
    }
  }
}

.btnWrapper {
  padding: 30px 0 28px 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 760px) {
    padding: 45px 0 0 0;
  }
}
