.NextArrow {
  width: 23px;
  height: 1px;
  background-color: var(--bright-orange);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  &:before {
    width: 10px;
    height: 1px;
    position: absolute;
    top: 2px;
    left: -3px;
    content: '';
    transform: rotate(35deg);
    background-color: var(--bright-orange);
  }

  &:after {
    width: 10px;
    height: 1px;
    position: absolute;
    bottom: 3px;
    left: -3px;
    content: '';
    transform: rotate(-35deg);
    background-color: var(--bright-orange);
  }
}

.PrevArrow {
  width: 23px;
  height: 1px;
  background-color: var(--bright-orange);
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%);

  &:before {
    width: 10px;
    height: 1px;
    position: absolute;
    top: 3px;
    right: -3px;
    content: '';
    transform: rotate(145deg);
    background-color: var(--bright-orange);
  }

  &:after {
    width: 10px;
    height: 1px;
    position: absolute;
    bottom: 3px;
    right: -3px;
    content: '';
    transform: rotate(-145deg);
    background-color: var(--bright-orange);
  }
}

.arrowWrapper {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 25%;

  &:first-of-type {
    transform: translate(-35px, 0);
    left: 0;
  }

  &:last-of-type {
    transform: translate(35px, 0);
    right: 0;
  }
}

.arrowWrapper[data-style='article'] {
  top: 16px;
  right: 0;
  z-index: 1;

  &:first-of-type {
    right: 52px;
    left: auto;
  }

  &:last-of-type {
    transform: translate(0, 0);
    right: 43px;
  }
}