.progressBarTitle {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;
  padding: 8px 0 2px 0;
  max-width: 480px;
  margin: 0 auto;
}

.progressBar {
  height: 10px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px var(--brown-grey-30);
  max-width: 480px;
  margin: 0 auto;

  .progressLine {
    height: 8px;
    border-radius: 10px;
    transition-duration: 0.4s;
  }
}